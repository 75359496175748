/* eslint no-unused-vars: 0 */
/* global tw */
import React from 'react';
import moment from 'moment';
import { graphql, useStaticQuery } from 'gatsby';
import { jsx, css } from '@emotion/core';
import PropTypes from 'prop-types';

import SubTitle from './subtitle';

const getFlowValue = (stationId, allFlowData) => {
  // eslint-disable-next-line eqeqeq
  const flowObj = allFlowData.find(flow => flow.node.stationId == stationId);

  if (flowObj) {
    return flowObj.node.value;
  }

  console.log('Unable to find record for ', stationId);
};

const FlowCard = props => {
  const data = useStaticQuery(graphql`
    query FlowCardQuery {
      usgs: allUsgsWater {
        edges {
          node {
            name
            value
            dateTime
            stationId
          }
        }
      }
      cowater: allCoWater {
        edges {
          node {
            name
            value
            dateTime
            stationId
          }
        }
      }
    }
  `);
  const { edges: usgsFlows } = data.usgs;
  const { edges: cowaterFlows } = data.cowater;
  const allFlowData = [...usgsFlows, ...cowaterFlows];
  const asOfDate = moment().format('MMMM DD, YYYY');
  const { stationId, stationName } = props;

  return (
    <div>
      <h4 css={tw`pb-4 font-light text-xl uppercase text-center`}>
        As of: {asOfDate}
      </h4>
      <div css={tw`font-normal text-xl text-center pb-4`}>{stationName}</div>
      <div css={tw`font-normal text-4xl text-center`}>
        {getFlowValue(stationId, allFlowData)} cfs
      </div>
    </div>
  );
};

FlowCard.propTypes = {
  stationId: PropTypes.string,
  stationName: PropTypes.string,
};

export default FlowCard;
