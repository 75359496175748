/* eslint no-unused-vars: 0 */
/* global tw */
import React from 'react';
import { graphql } from 'gatsby';
import { jsx, css } from '@emotion/core';
import PropTypes from 'prop-types';

import SEO from '../components/seo';
import PageContainer from '../components/page-container';
import FishingReport from '../components/fishing-report';
import Title from '../components/title';
import FlowCard from '../components/flow-card';

const CurrentReport = props => {
  const { report } = props;
  if (report) {
    const { date, patterns } = report.node.frontmatter;
    const { html } = report.node;
    return <FishingReport date={date} report={html} patterns={patterns} />;
  }
  return 'No Current Report';
};

const PreviousReports = props => {
  const { reports } = props;

  if (reports && reports.length > 0) {
    return reports.map((report, k) => {
      const { date, patterns } = report.node.frontmatter;
      const { html } = report.node;
      return (
        <FishingReport date={date} report={html} patterns={patterns} key={k} />
      );
    });
  }
  return 'No Previous Reports';
};

const Template = ({ data }) => {
  const fm = data.page.frontmatter;
  const [latest, ...previous] = data.reports.edges;

  return (
    <PageContainer>
      <SEO title={fm.title} description={fm.metaDescription} />
      <div className="section">
        <Title text={fm.title} />
        <div className="columns">
          <div className="column is-two-thirds">
            <CurrentReport report={latest} />
          </div>
          <div className="column">
            <FlowCard stationId={fm.stationId} stationName={fm.stationName} />
          </div>
        </div>
      </div>
      <div className="section">
        <Title text="Previous Reports" />
        <PreviousReports reports={previous} />
      </div>
    </PageContainer>
  );
};

Template.propTypes = {
  data: PropTypes.shape({
    page: PropTypes.shape({
      frontmatter: PropTypes.shape({
        path: PropTypes.string,
        title: PropTypes.string,
        locationFilter: PropTypes.string,
        metaDescription: PropTypes.string,
        stationId: PropTypes.string,
        stationName: PropTypes.string,
      }),
    }),
    reports: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

export default Template;

export const pageQuery = graphql`
  query FishingReportsByPath($path: String!, $locationFilter: String!) {
    page: markdownRemark(frontmatter: { path: { eq: $path } }) {
      frontmatter {
        path
        title
        locationFilter
        metaDescription
        stationId
        stationName
      }
    }
    reports: allMarkdownRemark(
      filter: { frontmatter: { location: { eq: $locationFilter } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          html
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            patterns {
              name
              color
              size
            }
          }
        }
      }
    }
  }
`;
