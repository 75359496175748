/* eslint no-unused-vars: 0 */
/* global tw */
import React from 'react';
import { jsx, css } from '@emotion/core';
import PropTypes from 'prop-types';

import SubTitle from './subtitle';
import Content from './content';

const FishingReport = props => {
  const { date, report, patterns } = props;

  return (
    <div css={tw`mb-8`}>
      <SubTitle text={date} />
      <Content html={report} />

      <SubTitle text="Patterns" />
      <table>
        <tbody>
          {patterns.map((pattern, k) => (
            <tr key={k}>
              <td css={tw`pr-2`}>{pattern.name}</td>
              <td css={tw`pr-2`}>{pattern.size}</td>
              <td>{pattern.color}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

FishingReport.propTypes = {
  date: PropTypes.string.isRequired,
  report: PropTypes.string.isRequired,
  patterns: PropTypes.array,
};

export default FishingReport;
