/* eslint no-unused-vars: 0 */
/* global tw */
import React from 'react';
import { jsx, css } from '@emotion/core';
import PropTypes from 'prop-types';

const subStyle = css`
  ${tw`uppercase font-light text-xl text-red mt-0 mb-6`};
`;

const SubTitle = props => {
  const { text } = props;
  return <h2 css={subStyle}>{text}</h2>;
};

SubTitle.propTypes = {
  text: PropTypes.string.isRequired,
};

export default SubTitle;
