/* eslint no-unused-vars: 0 */
/* global tw */
import React from 'react';
import { Global, jsx, css } from '@emotion/core';

const content = css`${tw`pr-0 md:pr-10`};`;

const Content = (props) => (
  <div
    css={content}
    dangerouslySetInnerHTML={{
      __html: props.html
    }}
  />
);

export default Content;
